// Layout
// ========================================================================

body,
#root,
.wrapper {
  height: 100%;
}

.usage-back-color {
  background-color: #fafafc;
}

.pre-line {
  white-space: pre-line !important;
}

.cutomeSwitchColor {
  .form-check-input:checked {
    background-color: #21c44c;
    border-color: #21c44c;
  }
}

.flatpickr-wrapper {
  width: 100% !important;
}

.PhoneInputInput {
  &::placeholder {
    color: #b9b9c3 !important;
  }
}

.package-add-btn-text {
  color: #151aae;
}

.dark-blue-border-bottom {
  border-bottom: 1px solid #151aae;
  // padding-left: 10px;
  // padding-right: 10px;
  border-radius: 0;
}

.hoverForMapSaved:hover {
  background-color: rgba(21, 26, 174, 0.05);
}

.truncate-vertical-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.truncate-vertical-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3 !important;
  -webkit-box-orient: vertical;
}

.scroll-bar-hide {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-bar-hide::-webkit-scrollbar {
  display: none !important;
}

// .PhoneInputInput {
//   padding: 0.571rem 1rem;
//   background-color: #fff;
//   background-clip: padding-box;
//   border: 1px solid #d8d6de;
//   border-radius: 0.357rem;
//   display: block;
//   width: 100%;
//   font-size: 1rem;
//   font-weight: 400;
//   line-height: 1.45;
//   color: #6e6b7b;
//   appearance: none;
//   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//   border: none !important;
//   &:focus {
//     outline: none !important;
//   }
// }

.text-transparent {
  color: transparent !important;
}

.text-gray7070 {
  color: #707070 !important;
}

.similar-holder {
  .slick-track {
    min-width: 100% !important;
  }
}

.op-query1::after {
  position: absolute;
  bottom: 31px;
  left: 30px;
  content: "";
  width: 17px;
  height: 94px;
  border-top: 2px solid rgba(9, 79, 208, 0.15);
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid rgba(9, 79, 208, 0.15);
  border-radius: 2px;
  //z-index: 1;
}

.op-query1::before {
  position: absolute;
  top: 31px;
  left: 30px;
  content: "";
  width: 17px;
  height: 104px;
  border-bottom: 2px solid rgba(9, 79, 208, 0.15);
  border-right: 2px solid transparent;
  border-top: 2px solid transparent;
  border-left: 2px solid rgba(9, 79, 208, 0.15);
  border-radius: 2px;
  //z-index: 1;
}

.op-query2::after {
  position: absolute;
  bottom: 31px;
  left: 49.3%;
  content: "";
  width: 17px;
  height: 54px;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid rgba(9, 79, 208, 0.15);
  border-radius: 2px;
  //z-index: 1;
}

.op-query2::before {
  position: absolute;
  top: 31px;
  left: 49.3%;
  content: "";
  width: 17px;
  height: 54px;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  border-top: 2px solid transparent;
  border-left: 2px solid rgba(9, 79, 208, 0.15);
  border-radius: 2px;
  //z-index: 1;
}

.op-query3::after {
  position: absolute;
  bottom: 31px;
  left: 30px;
  content: "";
  width: 17px;
  height: 74px;
  border-top: 2px solid rgba(9, 79, 208, 0.15);
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid rgba(9, 79, 208, 0.15);
  border-radius: 2px;
  //z-index: 1;
}

.op-query3::before {
  position: absolute;
  top: 31px;
  left: 30px;
  content: "";
  width: 17px;
  height: 84px;
  border-bottom: 2px solid rgba(9, 79, 208, 0.15);
  border-right: 2px solid transparent;
  border-top: 2px solid transparent;
  border-left: 2px solid rgba(9, 79, 208, 0.15);
  border-radius: 2px;
  //z-index: 1;
}

.op-query4::after {
  position: absolute;
  bottom: 31px;
  left: 49.3%;
  content: "";
  width: 17px;
  height: 34px;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid rgba(9, 79, 208, 0.15);
  border-radius: 2px;
  //z-index: 1;
}

.op-query4::before {
  position: absolute;
  top: 31px;
  left: 49.3%;
  content: "";
  width: 17px;
  height: 34px;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  border-top: 2px solid transparent;
  border-left: 2px solid rgba(9, 79, 208, 0.15);
  border-radius: 2px;
  //z-index: 1;
}

.op-query5::after {
  position: absolute;
  bottom: 31px;
  left: 30px;
  content: "";
  width: 17px;
  height: 74px;
  border-top: 2px solid rgba(9, 79, 208, 0.15);
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid rgba(9, 79, 208, 0.15);
  border-radius: 2px;
  //z-index: 1;
}

.op-query5::before {
  position: absolute;
  top: 31px;
  left: 30px;
  content: "";
  width: 17px;
  height: 74px;
  border-bottom: 2px solid rgba(9, 79, 208, 0.15);
  border-right: 2px solid transparent;
  border-top: 2px solid transparent;
  border-left: 2px solid rgba(9, 79, 208, 0.15);
  border-radius: 2px;
  //z-index: 1;
}

.op-query6::after {
  position: absolute;
  bottom: 31px;
  left: 49.3%;
  content: "";
  width: 17px;
  height: 24px;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid rgba(9, 79, 208, 0.15);
  border-radius: 2px;
  //z-index: 1;
}

.op-query6::before {
  position: absolute;
  top: 31px;
  left: 49.3%;
  content: "";
  width: 17px;
  height: 24px;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  border-top: 2px solid transparent;
  border-left: 2px solid rgba(9, 79, 208, 0.15);
  border-radius: 2px;
  //z-index: 1;
}

.flex-1 {
  flex: 1 !important;
}
.z-100 {
  z-index: 100 !important;
}
.h100 {
  height: 100% !important;
}
.h100px {
  height: 100px !important;
}

.border-bottom-7070 {
  border-bottom: 1px solid rgba(112, 112, 112, 0.13);
}

.alert-bg-warning {
  background: rgba(221, 60, 57, 0.05) !important;
}

.blue-title-sidebar {
  color: #414de8;
}

.blue-badge-text {
  color: #3144c4;
}

.p-7px {
  padding: 4px 7px;
}

.bg-discount-gray {
  background-color: rgba(240, 240, 240, 0.8);
}

.bg-light-green {
  background-color: #e9f8ef;
}

.text-light-green {
  color: #2abc67;
}

.text-congrat-green {
  color: rgb(25, 198, 86);
}

.text-purple {
  color: rgb(113, 6, 196);
}

.text-congrat-gray {
  color: rgb(121, 122, 128);
}

.bg-congrat-gray {
  background-color: rgb(188, 188, 188, 0.08);
}

.bg-congrat-blue {
  background-color: rgb(44, 128, 245, 0.12);
}
.bg-veryLBlue {
  background-color: #dee1f2;
}

.text-invoice-dBlue {
  color: #240e7e;
}

.text-congrat-blue {
  color: rgb(44, 128, 245);
}

.blue-back-point {
  background-color: #414de8;
}

.blue-back-point-opacity11 {
  background-color: rgba(127, 17, 224, 0.11);
}

.agent-broker-label-color {
  color: #45454d;
}

.light-gray {
  background-color: #f2f2f2;
}

.selected-filter-item-border {
  border: 1px solid #ebefff;
}
.selected-filter-item-back {
  background: #ebefff;
}

.unselected-filter-item-border {
  border: 1px solid #e0e0e0;
}

.bg-grey {
  background-color: #f5f5f5;
}

.code-wrapper-style {
  display: flex;
  justify-content: center;
}

.is-valid {
  z-index: unset !important;
}
.input-group .form-control.is-invalid {
  z-index: unset !important;
}

.code-input-style {
  border-radius: 13px !important;
  border: solid 1px #bdc0c9;
  width: 59px;
  height: 71px;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  margin: 0 10px;

  &:focus {
    border: solid 1px #314bc9;
  }
}
.code-input-style:focus {
  border: solid 1px #314bc9;
}
.code-input-style-error {
  border: solid 1px #e10c0c;
}

.code-wrapper-style2 {
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.code-input-style2 {
  border-radius: 5px !important;
  border: solid 1px #bdc0c9;
  width: 55px;
  height: 55px;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  margin: 0 8px;

  &:focus {
    border: solid 1px #314bc9;
  }
}

@media (max-width: 1445px) {
  .code-input-style {
    width: 49px;
    height: 61px;
    font-size: 22px;
    margin: 0 8px;
    &:focus {
      border: solid 1px #314bc9;
    }
  }
  .code-input-style2 {
    width: 45px;
    height: 45px;
    font-size: 22px;
    margin: 0 2px 10px;
    &:focus {
      border: solid 1px #314bc9;
    }
  }
}
@media (max-width: 700px) {
  .code-input-style {
    width: 39px;
    height: 51px;
    font-size: 20px;

    &:focus {
      border: solid 1px #314bc9;
    }
  }
  .code-input-style2 {
    width: 35px;
    height: 35px;
    font-size: 20px;
    &:focus {
      border: solid 1px #314bc9;
    }
  }
}

// for disabling content
.disbaled-content {
  pointer-events: none;
  opacity: 0.4;
}

.mb-1-1 {
  margin-bottom: 6px !important;
}
.mt-1-1 {
  margin-top: 6px !important;
}
.mt-100 {
  margin-top: 100px !important;
}

.ms-1-1 {
  margin-left: 3px !important;
}

.no-padding-ul > ul > li > ul {
  padding-left: 0 !important;
}
.no-padding-ul > ul {
  padding: 0 !important;
}

.slick-list {
  z-index: 1;
}
.slick-dots li button {
  pointer-events: none;
}

.slick-list {
  overflow: visible !important;
}
.text-darker {
  color: #2e2e33;
}

.bg-input-empty {
  background-color: #f4f3f9 !important;
}

.text-gray2 {
  color: #92969a;
}
.border-gray2 {
  border-color: #92969a;
}
.fs-16-1 {
  font-size: 16px !important;
}
.fs-5 {
  font-size: 15.5px !important;
}
.fs-6-1 {
  font-size: 14px !important;
}
.fs-14 {
  font-size: 16px !important;
}
.fs-7 {
  font-size: 14px !important;
}
.fs-8 {
  font-size: 12px !important;
}
.fs-9 {
  font-size: 11px !important;
}
.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 22px !important;
}

.fs-12 {
  font-size: 24px !important;
}

.fs-13 {
  font-size: 20px !important;
}

.fs-15 {
  font-size: 18px !important;
}
.fs-16 {
  font-size: 25px !important;
}
.fs-17 {
  font-size: 21px !important;
}

.f-18 {
  font-size: 18px !important;
}

.pending {
  color: #e5bf00 !important;
}

.bg-pending {
  background-color: #e8c92f !important;
}

.no-back-bg {
  background-image: none !important;
}

.slick-slide {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  height: 0;
  overflow: hidden;
}
.slick-active {
  pointer-events: auto;
  height: 100% !important;
  overflow: visible !important;
}
.bg-blue {
  background-color: #006bff !important;
  border-color: #006bff !important;
}

.bg-img-empty {
  background-image: none !important;
}

.social-gray-bg {
  background-color: #f8fafb !important;
}

.border-success-form {
  border-color: #28c76f !important;
}

.border-error-form {
  border-color: #ea5455 !important;
}
.text-error-form {
  color: #ea5455 !important;
}

// discount react select options
.margin-left-9 {
  margin-left: 9px;
}
.discount-options-holder {
  margin: 5px 10px 5px 0;
  padding: 5px 9px;
  border-radius: 4px;
}
.discount-options-holder2 {
  margin: 1px 8px 8px 5px;
  padding: 7px 9px;
  border-radius: 3px;
  line-height: 9px;
}
.outlineContainerCustome {
  line-height: 12px;
  margin-top: 5px;
}

// staff management
.fs-99 {
  font-size: 9px !important;
}
.padding-badge-small {
  padding: 3px !important;
}
.ms-badge-small {
  margin-left: 5px;
}
.badge-custome-size {
  width: 17px;
  height: 17px;
  text-align: center;
  line-height: 10px !important;
}
.general-size-ripple-button {
  width: 144px;
  height: 40px;
  font-size: 16px !important;
}

.searchFilterLabelGeneral {
  color: #2e2e33 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.customeInfoFontSize {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  font-size: 14px;
}

.staffInfoTitle {
  color: #92969a;
}
.staffInfoText {
  color: #2e2e33;
}
.linkBlue {
  color: #006bff;
}

// staff information roles edit and ...
.hoverClick {
  background: red;
}
.saveBtColor {
  background-color: #04cad0 !important;
}
.roleHolderClick {
  background-color: #e1e0ea !important;
}
.roleHolder:hover {
  background-color: #e1e0ea !important;
}

// .roles-list {
//   border-radius: 2px;
//   background-color: rgba(244, 243, 249, 0.6);
//   padding: 6px 0px;
//   margin-top: 10px;
//   border: solid 1px #e1e0ea;
//   max-height: 230px;
//   min-height: 160px;
//   overflow-y: auto;
// }

// .active-inactive-show {
//   margin-left: 3px;
//   width: 15px;
//   height: 15px;
//   border-radius: 100px;
// }
// .active-inactive-show2 {
//   margin-left: 4px;
//   width: 11px;
//   height: 11px;
//   border-radius: 100px;
//   margin-top: 2px;
// }

// gallery show component
// .padding-5-px {
//   padding: 5px !important;
// }
// .padding-1-px {
//   padding: 1px !important;
// }
// .radius-55 {
//   border-radius: 5px !important;
// }
// .radius-22 {
//   border-radius: 2px !important;
// }
.fit-coverr {
  object-fit: cover;
}

.button-list-background {
  background: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
}
// register

.register-logo-liner-big {
  padding: 2px;
  width: 30px;
  border-radius: 5px;
  background-color: rgba(25, 37, 124, 0.32);
  transform: translateX(3px);
}
.register-logo-liner-small {
  padding: 2px;
  border-radius: 100%;
  background-color: rgba(25, 37, 124, 0.32);
  margin-left: 3px;
  transform: translateX(3px);
}

.border-gray {
  border: 1px solid #e5e5e5;
}

.bg-gray-card {
  background-color: #e5e5e5;
}

.register-dashboard-name {
  color: #19257c;
  font-weight: 600;
}
.blue-color-invoice {
  color: #19257c;
}

.free-trial-green {
  color: #37c070;
  background: #eaf8f0;
}

.black-color-invoice {
  color: #313136;
}
.black-dark {
  color: #313136;
}

.placeholder-inputText-style {
  &::placeholder {
    font-size: 16px !important;
  }
}
.multiLanguage-textarea {
  resize: none;
}

.customToggleColor .form-check-input:checked {
  background-color: #30d158 !important;
}
// spacing
.w62 {
  width: 62%;
}
.w90 {
  width: 90%;
}

.pad1 {
  padding: 1px;
}
.pad2 {
  padding: 2px;
}
.pad3 {
  padding: 3px;
}
.pad4 {
  padding: 4px;
}
.padR5 {
  padding-right: 5px;
}
.padL5 {
  padding-left: 5px;
}
.padL7 {
  padding-left: 7px;
}
.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.px-125 {
  padding-left: 10px;
  padding-right: 10px;
}
.px5 {
  padding-left: 5px;
  padding-right: 5px;
}
.pb-3p {
  padding-bottom: 3px;
}
.py-7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.py-127 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.py-5p {
  padding-top: 5px;
  padding-bottom: 5px;
}
.py-3p {
  padding-top: 3px;
  padding-bottom: 3px;
}
.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ms-10 {
  margin-left: 10px !important;
}
.ms-5 {
  margin-left: 5px !important;
}
.mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.hight50 {
  height: 100px !important;
}

// bg-color
.bg-blue100 {
  background-color: rgba(51, 66, 175, 0.05) !important;
}

.bg-gray100 {
  background-color: #f2f2f2 !important;
}
.bg-lBlue {
  background-color: #f4f5fb !important;
}
.bg-VLBlue {
  background-color: #f7f7faa3 !important;
}
.bg-lightBlue {
  background-color: #f2fbfd !important;
}
.bg-VlightBlue {
  background-color: #e8e9fd !important;
}
.bg-darkBlue {
  background-color: #3342af !important ;
}
.bg-lightGreen {
  background-color: #edf6f6 !important;
}
.bg-greeny {
  background-color: #19c656 !important;
}
.bg-Lyellow {
  background-color: #e8c92f !important;
}
.bg-LRed {
  background-color: #fef5f5 !important;
}
.text-lightGray {
  color: #bcbcbc;
}

.border-lightGray-left-bottom {
  border-left: 1px solid #bcbcbc;
  border-bottom: 1px solid #bcbcbc;
}

.text-justify {
  text-align: justify !important;
}

.expire-red-text {
  color: #dd1435 !important;
}

.text-link-blue {
  color: #2c80f5;
}

.bg-card-checkout {
  background-color: #f5f3fa;
}
.bg-plus-light-gray {
  background-color: #e3e3e6 !important;
}

.bg-gray10 {
  background-color: #f8f8f8 !important;
}
.bg-white10 {
  background-color: #fff !important;
}
.bg-grayBlue {
  background-color: #f6f7fe !important;
}

.bg-very-light-green {
  background-color: #f4fcf9 !important;
}

.color-darkGreen {
  color: #1a928d !important;
}
.color-DRblue100 {
  color: #3342af !important;
}
.color-drkBlue {
  color: #094fd0 !important;
}
.color-ddrkBlue {
  color: #314bc9 !important;
}

.border-light-gray-dashed {
  border: 1px dashed #c4c8cc !important;
}
.borderBottom-gray {
  border-bottom: 1px solid #bcbcbc !important;
}

.bd-color-drkBlue {
  background-color: #094fd0 !important;
}
.color-gray100 {
  color: #797a80 !important;
}
.color-yellow {
  color: #e8c92f !important;
}
.color-greeny {
  color: #19c656 !important;
}
.color-dBlack {
  color: #1d1d1d !important;
}
.color-dGray {
  color: #666566 !important;
}
.color-ddGray {
  color: #636263 !important;
}
.color-gray999 {
  color: #999999 !important;
}
.color-dRed {
  color: #dd3c39 !important;
}
.color-black32 {
  color: #323233 !important;
}
.color-blue07 {
  color: #07aed0 !important;
}

.group-membership-item-bg {
  background: #f7f9fe;
}

// .select__dropdown-indicator > svg {
//   color:red  !important;
// }

.borderColor-green {
  border: 2px solid #1a928d !important;
}
.borderColor-Lgray {
  border: 1px solid #e3e3e6 !important;
}
.borderColor-gray10 {
  border: 2px solid #f8f8f8 !important;
}
.borderColor-blue {
  border: 2px solid #094fd0 !important;
}
.borderColor-dblue {
  border: 2px solid #314bc9 !important;
}
.borderColor-Lgreen {
  border: 2px solid #edf6f6 !important;
}
.border-no {
  border: none !important;
}
.border-gary {
  border: 1px solid #d8d6de !important;
}

.curcle {
  width: 10px;
  height: 10px;
  border: 1px solid #3342af;
  border-radius: 50%;
  background-color: #3342af;
}
.circle {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.verify-button {
  width: 78px;
  height: 31px;
  border-radius: 6px;
  background-color: #094fd0;
}
.font-bold {
  font-weight: bold;
}
// custom multi slider of brokerage group
.multi-range-slider-brokerage .bar-left {
  box-shadow: none !important;
}
.multi-range-slider-brokerage .bar-inner {
  box-shadow: none !important;
  border: none !important;
}
.multi-range-slider-brokerage .bar-right {
  box-shadow: none !important;
}
.multi-range-slider-brokerage .thumb {
  &:before {
    box-shadow: none !important;
    border: none !important;
  }
  box-shadow: none !important;
  border: none !important;
}
// input radio button

.radioButoon {
  border: 2px solid #19257c;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.checkedRadioButton {
  border-radius: 50%;
  // width: 10px;
  // height: 10px;
  width: 100%;
  height: 100%;
  background-color: #19257c;
}
.radioButton-brokerageGroupe {
  border: 2px solid #d2d2d2;
  border-radius: 50%;
  width: 17px;
  height: 17px;
}
.checkedRadioButton-brokerageGroupe {
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background-color: #3144c4;
}
.h60 {
  height: 60px;
}
.h40 {
  height: 40px;
}
.h150 {
  height: 150px;
}
.h10 {
  height: 10%;
}
.h90 {
  height: 90%;
}
.w45 {
  width: 45%;
}
.w10 {
  width: 10%;
}
.w60 {
  width: 60% !important;
}
.w-h-35 {
  width: 35px !important;
  height: 35px !important;
}
.bred {
  border: 1px solid red;
}
.bgreen {
  border: 2px solid green;
}

.active-green-text {
  color: #2dbc64 !important;
}

.border-green {
  border: 1px solid #19c656 !important;
}

// gap
.gap-8px {
  gap: 8px !important;
}

.lightGray-border-bottom {
  border-bottom: 1px solid #f1f1f2 !important;
}

// custom sytle of navItem for tab in "mls-verification" section
.custom-nav-link {
  position: relative !important;
  display: inline-block !important;
  padding-bottom: 5px !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 70px !important;
  font-family: MontserratMedium !important;
  font-size: 16px !important;

  &:after {
    content: "";
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 2px !important;
    padding: 0 !important;
    background: #047dea !important;
    color: #047dea !important;
  }
}
.position-absolute-closeIcon {
  position: absolute;
  right: -5px;
  top: -13px;
}

// Vertical Menu
// ========================================================================
.vertical-layout .main-menu {
  &.expanded {
    .navigation {
      li:not(.open) {
        & > ul {
          display: block !important;
        }
      }
    }
  }
  .navigation {
    li:not(.open) {
      a {
        &:after {
          transform: rotate(0deg) !important;
        }
      }
    }
    // a.active {
    //   z-index: 1;
    //   background: #314bc9; //linear-gradient(118deg, rgba($primary, 1), rgba($primary, 0.7));
    //   // background: #f7f6f9; //linear-gradient(118deg, rgba($primary, 1), rgba($primary, 0.7));
    //   // box-shadow: 0 0 10px 1px rgba($primary, 0.7);
    //   color: #fff;
    //   font-weight: 400;
    //   border-radius: 4px;
    // }

    li.nav-item {
      .menu-item {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

body #root .wrapper .ps__rail-y {
  left: auto !important ;
  right: 1px !important;
}

// Profile Dropdown (Common)
// ========================================================================
.vertical-layout,
.horizontal-layout {
  .header-navbar:not(.navbar-horizontal) .navbar-container ul.navbar-nav {
    li.dropdown {
      .dropdown-menu {
        top: 3.5rem !important;
      }
      &.dropdown-user {
        .dropdown-menu {
          top: 4rem !important;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .vertical-layout,
  .horizontal-layout {
    .header-navbar:not(.navbar-horizontal) .navbar-container ul.navbar-nav {
      li.dropdown {
        .dropdown-menu {
          top: 3.5rem !important;
        }
        &.dropdown-user {
          .dropdown-menu {
            top: 5rem !important;
          }
        }
      }
    }
  }
}

// Horizontal Menu
// ========================================================================
.horizontal-layout {
  &.navbar-static .header-navbar.navbar-scrolled {
    background-color: $white;
    box-shadow: rgba($black, 0.05) 0px 4px 20px 0px;
  }

  // Main Navbar
  .header-navbar {
    .navbar-container {
      .dropdown-language,
      .dropdown-cart,
      .dropdown-notification,
      .dropdown-user {
        .dropdown-menu {
          top: 3rem !important;
        }
      }
    }
  }

  // Menu
  .horizontal-menu-wrapper {
    #main-menu-navigation {
      .dropdown {
        .dropdown-menu.first-level {
          top: 4.6rem !important;
        }
        .dropdown-submenu {
          &.sidebar-group-active.active {
            .dropdown-toggle {
              color: $body-color;
            }
          }
          .dropdown-toggle:after {
            margin-top: -5px;
          }
        }

        .dropdown-item.active {
          color: $primary !important;
          background-color: $body-bg !important;
        }
      }
      .nav-item:not(.dropdown):not(.dropdown-submenu) {
        a.nav-link.active {
          background: linear-gradient(
            118deg,
            rgba($primary, 1),
            rgba($primary, 0.7)
          );
          box-shadow: 0 0 6px 1px rgba($primary, 0.6);
          color: $white;
          border-radius: 4px;
        }
      }
    }

    .navbar-horizontal {
      ul.dropdown-menu {
        .nav-item.active > a {
          border-bottom: none;
        }

        // Scrollbar styles
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: $gray-500;
          border-radius: 20px;
        }

        &::-webkit-scrollbar-track {
          background: $gray-300;
          border-radius: 30px;
        }
      }
    }
  }
}

// Layout BreadCrumbs
// =======================================================================
.content-header {
  .content-header-title {
    font-weight: 500;
    color: #19257c;
    padding-right: $spacer;
    border-right: 1px solid lighten($gray-600, 10%);
  }
  .content-header-right {
    .dropdown-toggle:after {
      display: none;
    }
  }
}

// Bookmarks search
// ========================================================================
.header-navbar .navbar-container {
  .navbar-nav {
    .autocomplete-container .suggestion-item span {
      line-height: 1.2;
    }
  }
}

// Component Code
// ========================================================================
.card-snippet {
  pre {
    margin-top: 0;
    border-radius: 0.5rem;
  }
}

// UserDashboard Analytics
// =======================================================================

.card-congratulations {
  .avatar svg {
    height: 28px !important;
    width: 28px !important;
  }
}

// Card Analytics
// =======================================================================
.avg-session-progress {
  height: 6px;
}

// Block UI
// ========================================================================
.block-ui-container {
  .block-ui-overlay {
    background: rgba($black, 0.6);
  }
}

// React SlideDown
// ========================================================================
.react-slidedown {
  &::-webkit-scrollbar {
    width: 0px;
  }
}

// Router Transitions
// ========================================================================
.animate__animated.animate__zoomIn,
.animate__animated.animate__fadeInLeft {
  --animate-duration: 1s;
}

// App Ecommerce
// ========================================================================
.ecommerce-application {
  .content-wrapper.animate__animated {
    animation: none;
  }
}

.profile-text-color {
  .select__placeholder {
    color: #b9b9c3 !important;
  }
}

// Customizer
// ========================================================================
.customizer {
  .react-select {
    width: 100%;
    max-width: 200px;

    .select__menu {
      z-index: 2;
    }
  }
}

// Media Query to fix navbar dropdown on sm screen
@media (max-width: 576px) {
  .header-navbar .navbar-container .dropdown.show .dropdown-menu {
    width: auto;
    left: 0 !important;
  }
}

@media (max-width: 991.98px) {
  .customeInfoFontSize {
    font-size: 12px;
  }
}
