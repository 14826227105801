// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) and (max-width: 1580px) {
  // m
  .ms-1 {
    margin-left: 0.8rem !important;
  }

  .fs-1 {
    font-size: 1.7rem !important;
  }
  .fs-2 {
    font-size: 1.45rem !important;
  }
  .fs-3 {
    font-size: 1.25rem !important;
  }
  .fs-4 {
    font-size: 1rem !important;
  }
  .fs-5 {
    font-size: 14px !important;
  }
  .p-7px {
    padding: 3.6px 6.7px;
  }
  .code-wrapper-style {
    margin: 35px 0;
    display: flex;
    justify-content: center;
  }
  .mb-1-1 {
    margin-bottom: 5px !important;
  }
  .mt-1-1 {
    margin-top: 5px !important;
  }
  .mt-100 {
    margin-top: 90px !important;
  }
  .fs-5 {
    font-size: 14.5px !important;
  }
  .fs-6-1 {
    font-size: 12px !important;
  }
  .fs-14 {
    font-size: 14px !important;
  }
  .ms-1-1 {
    margin-left: 2.5px !important;
  }
  .fs-8 {
    font-size: 11px !important;
  }
  .fs-9 {
    font-size: 10px !important;
  }
  //   .fs-10 {
  //     font-size: 10px !important;
  //   }

  .fs-11 {
    font-size: 20px !important;
  }

  //   .fs-12 {
  //     font-size: 22px !important;
  //   }

  .fs-13 {
    font-size: 18px !important;
  }

  .fs-15 {
    font-size: 16px !important;
  }
  .fs-16 {
    font-size: 16px !important;
  }
  //   .fs-17 {
  //     font-size: 21px !important;
  //   }

  .f-18 {
    font-size: 18px !important;
  }
  // discount react select options
  .margin-left-9 {
    margin-left: 8px;
  }
  .discount-options-holder {
    margin: 4px 9px 4px 0;
    padding: 4px 8px;
    border-radius: 4px;
  }
  .discount-options-holder2 {
    margin: 1px 7px 7px 4px;
    padding: 6px 8px;
    border-radius: 3px;
    line-height: 8px;
  }
  .outlineContainerCustome {
    line-height: 12px;
    margin-top: 4px;
  }
  // staff management
  .fs-99 {
    font-size: 8px !important;
  }
  .padding-badge-small {
    padding: 2.8px !important;
  }
  .ms-badge-small {
    margin-left: 4px;
  }
  .badge-custome-size {
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 10px !important;
  }
  .general-size-ripple-button {
    width: 134px;
    height: 36px;
    font-size: 14px !important;
  }

  .searchFilterLabelGeneral {
    color: #2e2e33 !important;
    font-size: 13.5px !important;
    font-weight: 500 !important;
  }
  .customeInfoFontSize {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    font-size: 12px;
  }

  .form-control {
    font-size: 0.8rem !important;
  }

  .noFontSizeFormControl {
    .form-control {
      font-size: 1rem !important;
      border-radius: 3px !important;
    }
  }

  .PhoneInputInput {
    font-size: 0.8rem !important;
  }
  .fs-10 {
    font-size: 9px !important;
  }
  .fs-7 {
    font-size: 12px !important;
  }
  .fs-14 {
    font-size: 14px !important;
  }

  .fs-12 {
    font-size: 11px !important;
  }

  .fs-17 {
    font-size: 15px !important;
  }

  .fs-18 {
    font-size: 16px !important;
  }

  .fs-20 {
    font-size: 18px !important;
  }

  .fs-22 {
    font-size: 18px !important;
  }
  .fs-24 {
    font-size: 22px !important;
  }
  .fs-30 {
    font-size: 27px !important;
  }
  .fs-36 {
    font-size: 36px !important;
  }
  .p-0\/5 {
    padding: 0.4rem !important;
  }

  .py-0\/5 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
  }

  .py-0\/25 {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
  }

  .p-0\/5 {
    padding: 0.4rem !important;
  }

  .px-0\/5 {
    padding-left: 0.4rem !important;
    padding-right: 0.4rem !important;
  }

  .mt-0\/5 {
    margin-top: 0.4rem !important;
  }

  .mt-12 {
    margin-top: 0.65rem !important;
  }

  .me-0\/5 {
    margin-right: 0.4rem !important;
  }

  .ps-0\/5 {
    padding-left: 0.4rem !important;
  }

  .ms-0\/5 {
    margin-left: 0.4rem !important;
  }

  .my-0\/5 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }

  .mb-0\/5 {
    margin-bottom: 0.4rem !important;
  }

  .mb-0\/25 {
    margin-bottom: 0.2rem !important;
  }

  .mx-0\/5 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important;
  }

  .mx-0\/25 {
    margin-right: 0.2rem !important;
    margin-left: 0.2rem !important;
  }

  .ms-0\/25 {
    margin-right: 0.2rem !important;
    margin-left: 0.2rem !important;
  }

  .me-0\/25 {
    margin-right: 0.2rem !important;
    margin-left: 0.2rem !important;
  }

  .gap-0\/5 {
    grid-gap: 0.4rem !important;
    gap: 0.4rem !important;
  }

  .gap-1\/5 {
    grid-gap: 1.3rem !important;
    gap: 1.3rem !important;
  }

  .gap-5\/5 {
    grid-gap: 5.2rem !important;
    gap: 5.2rem !important;
  }
}
