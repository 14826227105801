// XXX-Large devices (larger desktops, 1580px and up)
@media (min-width: 1580px) {
  .form-control {
    font-size: 1rem !important;
  }

  .noFontSizeFormControl {
    .form-control {
      font-size: 1rem !important;
      border-radius: 3px !important;
    }
  }
  .PhoneInputInput {
    font-size: 1rem !important;
  }
  .fs-7 {
    font-size: 14px !important;
  }
  .fs-10 {
    font-size: 10px !important;
  }
  .fs-14 {
    font-size: 16px !important;
  }

  .fs-12 {
    font-size: 12px !important;
  }

  .fs-17 {
    font-size: 17px !important;
  }

  .fs-18 {
    font-size: 18px !important;
  }

  .fs-20 {
    font-size: 20px !important;
  }

  .fs-22 {
    font-size: 22px !important;
  }
  .fs-24 {
    font-size: 24px !important;
  }
  .fs-30 {
    font-size: 30px !important;
  }
  .fs-36 {
    font-size: 36px !important;
  }
  .p-0\/5 {
    padding: 0.5rem !important;
  }

  .py-0\/5 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-0\/25 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .p-0\/5 {
    padding: 0.5rem !important;
  }

  .px-0\/5 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .mt-0\/5 {
    margin-top: 0.5rem !important;
  }

  .mt-12 {
    margin-top: 0.75rem !important;
  }

  .me-0\/5 {
    margin-right: 0.5rem !important;
  }

  .ps-0\/5 {
    padding-left: 0.5rem !important;
  }

  .ms-0\/5 {
    margin-left: 0.5rem !important;
  }

  .my-0\/5 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .mb-0\/5 {
    margin-bottom: 0.5rem !important;
  }

  .mb-0\/25 {
    margin-bottom: 0.25rem !important;
  }

  .mx-0\/5 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-0\/25 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .ms-0\/25 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .me-0\/25 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .gap-0\/5 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .gap-1\/5 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .gap-5\/5 {
    grid-gap: 5.5rem !important;
    gap: 5.5rem !important;
  }
}
