/* Fonts */
@font-face {
  font-family: MontserratSemiBold;
  src: url("./Montserrat-SemiBold.ttf");
  font-style: normal;
}
@font-face {
  font-family: MontserratBold;
  src: url("./Montserrat-Bold.ttf");
  font-style: normal;
}
@font-face {
  font-family: MontserratLight;
  src: url("./Montserrat-Light.ttf");
  font-style: normal;
}

@font-face {
  font-family: OpenSansSemibold;
  src: url("./OpenSans-SemiBold.ttf");
  font-style: normal;
}

@font-face {
  font-family: OpenSansRegular;
  src: url("./OpenSans-Regular.ttf");
  font-style: normal;
}

@font-face {
  font-family: MontserratRegular;
  src: url("./Montserrat-Regular.ttf");
  font-style: normal;
}

@font-face {
  font-family: MontserratMedium;
  src: url("./Montserrat-Medium.ttf");
  font-style: normal;
}

@font-face {
  font-family: PoppinsRegular;
  src: url("./Poppins-Regular.ttf");
  font-style: normal;
}

@font-face {
  font-family: PoppinsSemibold;
  src: url("./Poppins-SemiBold.ttf");
  font-style: normal;
}

@font-face {
  font-family: PoppinsMedium;
  src: url("./Poppins-Medium.ttf");
  font-style: normal;
}
@font-face {
  font-family: PoppinsBold;
  src: url("./Poppins-Bold.ttf");
  font-style: normal;
}

@font-face {
  font-family: RobotoMedium;
  src: url("./Roboto-Medium.ttf");
  font-style: normal;
}

@font-face {
  font-family: InterBlack;
  src: url("./Inter-Black.ttf");
  font-style: normal;
}

@font-face {
  font-family: InterBold;
  src: url("./Inter-Bold.ttf");
  font-style: normal;
}

@font-face {
  font-family: InterExtraBold;
  src: url("./Inter-ExtraBold.ttf");
  font-style: normal;
}

@font-face {
  font-family: InterExtraLight;
  src: url("./Inter-ExtraLight.ttf");
  font-style: normal;
}

@font-face {
  font-family: InterLight;
  src: url("./Inter-Light.ttf");
  font-style: normal;
}

@font-face {
  font-family: InterMedium;
  src: url("./Inter-Medium.ttf");
  font-style: normal;
}

@font-face {
  font-family: InterRegular;
  src: url("./Inter-Regular.ttf");
  font-style: normal;
}
@font-face {
  font-family: InterSemiBold;
  src: url("./Inter-SemiBold.ttf");
  font-style: normal;
}

@font-face {
  font-family: InterThin;
  src: url("./Inter-Thin.ttf");
  font-style: normal;
}

@font-face {
  font-family: Kapakana;
  src: url("./Kapakana.ttf");
  font-style: normal;
}

/* Classes */
/* montserrat */
.font-montserrat-semiBold {
  font-family: MontserratSemiBold !important;
}

.font-montserrat-regular {
  font-family: MontserratRegular !important;
}

.font-montserrat-medium {
  font-family: MontserratMedium !important;
}

.font-montserrat-bold {
  font-family: MontserratBold !important;
}

.font-montserrat-light {
  font-family: MontserratLight !important;
}

/* opensans */
.font-opensans-semibold {
  font-family: OpenSansSemibold !important;
}
.font-opensans-regular {
  font-family: OpenSansRegular !important;
}

/* poppin */
.font-poppin-regular {
  font-family: PoppinsRegular !important;
}

.font-poppin-semibold {
  font-family: PoppinsSemibold !important;
}

.font-poppin-medium {
  font-family: PoppinsMedium !important;
}
.font-poppin-bold {
  font-family: PoppinsBold !important;
}

/* Roboto */
.font-roboto-medium {
  font-family: RobotoMedium !important;
}

/* Inter */
.font-inter-black {
  font-family: InterBlack !important;
}

.font-inter-bold {
  font-family: InterBold !important;
}

.font-inter-extraBold {
  font-family: InterExtraBold !important;
}
.font-inter-extraLight {
  font-family: InterExtraLight !important;
}

.font-inter-light {
  font-family: InterLight !important;
}

.font-inter-medium {
  font-family: InterMedium !important;
}

.font-inter-regular {
  font-family: InterRegular !important;
}
.font-inter-semiBold {
  font-family: InterSemiBold !important;
}

.font-inter-thin {
  font-family: InterThin !important;
}

.font-kapakana {
  font-family: Kapakana !important;
}
