.toastContainer {
  width: 420px;
  height: 78px;
}
.iconContainer {
  flex-basis: 48px;
  height: 100%;
}

.messageContainer {
  flex: 1 1 0%;
}
.headerStyle {
  font-size: 16px;
  font-weight: bold;
}

.messageStyle {
  font-size: 14px;
  font-weight: normal;
  text-align: start;
  display: block;
  padding: 0;
  margin: 0;
  flex: none;
}
.closeButtonStyle {
  border: none;
  outline: none;
  background: inherit;
  margin-top: 15px;
}
