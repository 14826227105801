@import "./base/bootstrap.scss";
@import "./base/bootstrap-extended.scss";
@import "./base/colors.scss";
@import "./base/components.scss";

// Themes
@import "./base/themes/dark-layout.scss";
@import "./base/themes/bordered-layout.scss";
@import "./base/themes/semi-dark-layout.scss";

// @import './base/custom-rtl.scss';

// React Specific
@import "react/index";

// .numInput.cur-year {
//   pointer-events: none !important;
// }

.PhoneInputInput {
  padding: 0.571rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: none !important;
  &:focus {
    outline: none !important;
  }
}

.CUstomeImageLogoUploader {
  border-radius: 100%;
  width: 105px !important;
  height: 105px !important;
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-line-through {
  text-decoration: line-through !important;
}
.fs-16-1 {
  font-size: 16px !important;
}

.fs-10 {
  font-size: 10px !important;
}
.fs-7 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-36 {
  font-size: 36px !important;
}
.fs-37 {
  font-size: 37px !important;
}

.fs-125{
  font-size: 125px !important;
}

.fs-16-1 {
  font-size: 16px !important;
}

.p-0\/5 {
  padding: 0.5rem !important;
}

.py-0\/5 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-0\/25 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.p-0\/5 {
  padding: 0.5rem !important;
}

.px-0\/5 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-0\/25 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-10 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.mt-0\/25 {
  margin-top: 0.25rem !important;
}

.mt-0\/5 {
  margin-top: 0.5rem !important;
}

.mt-2\/5 {
  margin-top: 2.5rem !important;
}

.mt-12 {
  margin-top: 0.75rem !important;
}

.me-0\/5 {
  margin-right: 0.5rem !important;
}

.ps-0\/5 {
  padding-left: 0.5rem !important;
}

.ms-0\/5 {
  margin-left: 0.5rem !important;
}

.my-0\/5 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.mb-0\/5 {
  margin-bottom: 0.5rem !important;
}

.mb-0\/25 {
  margin-bottom: 0.25rem !important;
}

.mx-0\/5 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-0\/25 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.ms-0\/25 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.me-0\/25 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.gap-0\/5 {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}

.gap-1\/5 {
  grid-gap: 1.5rem !important;
  gap: 1.5rem !important;
}

.gap-4 {
  grid-gap: 4rem !important;
  gap: 4rem !important;
}

.gap-5 {
  grid-gap: 5rem !important;
  gap: 5rem !important;
}

.gap-5\/5 {
  grid-gap: 5.5rem !important;
  gap: 5.5rem !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-78 {
  width: 78% !important;
}

.w-73 {
  width: 73% !important;
}

.w-60 {
  width: 60% !important;
}

.w-54 {
  width: 54% !important;
}

.w-48 {
  width: 48% !important;
}

.w-46 {
  width: 46% !important;
}

.w-45 {
  width: 45% !important;
}

.w-44 {
  width: 44% !important;
}

.w-42 {
  width: 42% !important;
}

.w-40 {
  width: 40% !important;
}

.w-30 {
  width: 30% !important;
}

.w-25 {
  width: 25% !important;
}

.w-23 {
  width: 23% !important;
}

.w-22 {
  width: 22% !important;
}

.w-20 {
  width: 20% !important;
}

.w-15 {
  width: 15% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-37 {
  height: 37% !important;
}

.h-38 {
  height: 38% !important;
}

.h-39 {
  height: 39% !important;
}

.h-40 {
  height: 40% !important;
}

.h-42 {
  height: 42% !important;
}

.h-43 {
  height: 42% !important;
}

.h-45 {
  height: 45% !important;
}

.h-46 {
  height: 46% !important;
}

.h-47 {
  height: 47% !important;
}

.h-48 {
  height: 48% !important;
}

.h-49 {
  height: 49% !important;
}

.h-51 {
  height: 51% !important;
}

.h-52 {
  height: 52% !important;
}

.h-53 {
  height: 53% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.bg-skyBlue {
  background-color: rgba(9, 79, 208, 0.06) !important;
}

.bg-skyBlue2 {
  background-color: rgba(9, 79, 208, 0.06) !important;
}
.bg-MmcepiYellow {
  background-color: #f7ce46 !important;
}

.text-dark {
  color: #2e2e33 !important;
}
.text-lightGray {
  color: #797a80 !important;
}
.bg-grey {
  background-color: #f8f8f8 !important;
}

.bg-grey2 {
  background-color: #f2f2f2 !important;
}

.text-grey {
  color: #92969a !important;
}

.text-grey2 {
  color: #5e5873 !important;
}

.py-6 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-2\/5 {
  padding-top: 2.5rem !important;
}

.ms-\/05 {
  margin-left: 0.5rem !important;
}

.rounded-4 {
  border-radius: 0.9rem !important;
}

.rounded-10 {
  border-radius: 10px !important;
}

.bottom-40 {
  bottom: 40% !important;
}

.outline-none {
  outline: none !important;
}

.border-b-primary {
  border-bottom: 1px solid #094fd0 !important;
}

.left-0\/7 {
  left: 0.7rem !important;
}

.bottom-0\/7 {
  bottom: 0.7rem !important;
}

.right-0\/7 {
  right: 0.7rem !important;
}

.bottom-1 {
  bottom: 1rem !important;
}

.right-1 {
  right: 1rem !important;
}

.bottom-2 {
  bottom: 2rem !important;
}

.right-2 {
  right: 2rem !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.ms-sm-0\/5 {
  @media screen and (min-width: 577px) and (max-width: 768px) {
    margin-left: 0.5rem !important;
  }
}

.gap-sm-0\/5 {
  @media screen and (min-width: 577px) and (max-width: 768px) {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }
}

.start-1 {
  left: 1rem !important;
}

.start-1\/5 {
  left: 1.5rem !important;
}

.start-2 {
  left: 2rem !important;
}

.top-2 {
  top: 2rem !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px !important;
  height: 4px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 grey !important;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccdcf !important;
  border-radius: 7px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a8a4a4 !important;
}

@import "./core-sm.scss";
@import "./core-md.scss";
@import "./core-lg.scss";
@import "./core-xl.scss";
@import "./core-xxl.scss";
@import "./core-xxxl.scss";

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

@media screen and (max-width: 480px) {
  .header-navbar {
    width: 100% !important;
    margin: 0 !important;
  }

  html [data-col="1-column"] .header-navbar.floating-nav {
    width: 100% !important;
  }
}
