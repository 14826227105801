// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 768px) {
  .form-control {
    font-size: 0.7rem !important;
  }
  .noFontSizeFormControl {
    .form-control {
      font-size: 1rem !important;
      border-radius: 3px !important;
    }
  }
  .PhoneInputInput {
    font-size: 0.7rem !important;
  }
  .fs-10 {
    font-size: 8.5px !important;
  }
  .fs-7 {
    font-size: 11px !important;
  }
  .fs-14 {
    font-size: 15px !important;
  }

  .fs-12 {
    font-size: 10px !important;
  }

  .fs-17 {
    font-size: 14px !important;
  }

  .fs-18 {
    font-size: 15px !important;
  }

  .fs-20 {
    font-size: 17px !important;
  }

  .fs-22 {
    font-size: 17px !important;
  }
  .fs-24 {
    font-size: 21px !important;
  }
  .fs-30 {
    font-size: 26px !important;
  }
  .fs-36 {
    font-size: 36px !important;
  }
  .p-0\/5 {
    padding: 0.35rem !important;
  }

  .py-0\/5 {
    padding-top: 0.35rem !important;
    padding-bottom: 0.35rem !important;
  }

  .py-0\/25 {
    padding-top: 0.18rem !important;
    padding-bottom: 0.18rem !important;
  }

  .p-0\/5 {
    padding: 0.35rem !important;
  }

  .px-0\/5 {
    padding-left: 0.35rem !important;
    padding-right: 0.35rem !important;
  }

  .mt-0\/5 {
    margin-top: 0.35rem !important;
  }

  .mt-12 {
    margin-top: 0.6rem !important;
  }

  .me-0\/5 {
    margin-right: 0.35rem !important;
  }

  .ps-0\/5 {
    padding-left: 0.35rem !important;
  }

  .ms-0\/5 {
    margin-left: 0.35rem !important;
  }

  .my-0\/5 {
    margin-top: 0.35rem !important;
    margin-bottom: 0.35rem !important;
  }

  .mb-0\/5 {
    margin-bottom: 0.35rem !important;
  }

  .mb-0\/25 {
    margin-bottom: 0.18rem !important;
  }

  .mx-0\/5 {
    margin-right: 0.35rem !important;
    margin-left: 0.35rem !important;
  }

  .mx-0\/25 {
    margin-right: 0.18rem !important;
    margin-left: 0.18rem !important;
  }

  .ms-0\/25 {
    margin-right: 0.18rem !important;
    margin-left: 0.18rem !important;
  }

  .me-0\/25 {
    margin-right: 0.18rem !important;
    margin-left: 0.18rem !important;
  }

  .gap-0\/5 {
    grid-gap: 0.35rem !important;
    gap: 0.35rem !important;
  }

  .gap-1\/5 {
    grid-gap: 1.2rem !important;
    gap: 1.2rem !important;
  }

  .gap-5\/5 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }
}
